import * as React from 'react';
import Layout from '../components/common/Layout';
import MainContents from '../components/common/MainContents';
import PageHeading from '../components/ui/Pageheading';
import { Button } from '../components/ui/Button';

const NotFoundPage = () => (
    <Layout pageTitle="Not found. | FDM フードデリバリーマネージャー">
    <MainContents>
      <PageHeading>Page not found.</PageHeading>
      <p className="mt-7.5 md:mt-20 text-sm font-medium tracking-wide leading-loose text-center">
        お探しのページが見つかりません。<br />
        一時的にアクセスできない状況にあるか、<br />
        移動もしくは削除された可能性があります。<br />
        お手数ですが、TOPページから<br className="md:hidden" />再度アクセスしてください。
      </p>
      <div className="mt-10 mx-auto md:pb-20 max-w-3xs text-center">
        <Button color="gray" size="xs" href="/" className="w-full">TOPに戻る</Button>
      </div>
    </MainContents>
  </Layout>
  )

export default NotFoundPage;
